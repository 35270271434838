<template>
    <div class="Publicize page-default-config">
        <div class="content">
            <div class="cell">
                <van-swipe v-show="!loading" class="swiper-content" @change="onChange">

                    <van-swipe-item v-for="(item, index) of currentImage.list" :key="index">
                        <img :src="item.image" alt="宣发图" title="宣发图" @click="ImagePreview(item)">
                    </van-swipe-item>

                    <template #indicator>
                        <div class="custom-indicator">{{ current + 1 }}/{{ currentImage.list.length }}</div>
                    </template>
                </van-swipe>

                <van-loading class="loading" v-if="loading" type="spinner" color="#1989fa" />
            </div>
        </div>

        <div class="Publicize-button button" @click="DownLoadImage">
            {{ $t(`xuanfa.baocuntupian`) }}
        </div>
    </div>
</template>

<style scoped lang="scss">
.Publicize {
    width: 100%;

    .Publicize-button {
        width: 70%;
        margin: 0 auto;
    }

    .content {
        width: 100%;
        padding: 20px;
        text-align: center;

        .cell {
            display: inline-block;
            padding: 6px;
            text-align: center;
            border: 2px solid #125196;
            border-radius: 6px;
            background-color: rgba(18, 81, 150, .6);
            box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
            width: 60vw;
            height: 68vh;
            position: relative;

            .swiper-content {
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                font-size: 0px;
                object-fit: cover;
                display: block;
                border-radius: 4px;
            }

            .loading {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>


<style scoped lang="scss">
.custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
}
</style>


<script>
export default {

    data() {
        return {
            loading: false,
            columns: [
                { id: "1", text: 'العربية', value: '阿拉伯语' },
                { id: "2", text: 'Brasil', value: '巴西语' },
                { id: "3", text: 'Polska', value: '波兰语' },
                { id: "4", text: 'Deutsch', value: '德语' },
                { id: "5", text: 'Русский', value: '俄语' },
                { id: "6", text: 'Français', value: '法语' },

                { id: "7", text: '繁体中文', value: '繁体中文' },
                { id: "8", text: 'Filipino', value: '菲律宾' },
                { id: "9", text: 'Suomi', value: '芬兰语' },
                { id: "10", text: '한국어', value: '韩语' },
                { id: "11", text: 'Nederlands', value: '荷兰语' },

                { id: "12", text: '简体中文', value: '简体中文' },
                { id: "13", text: 'Romance', value: '罗曼娜' },
                { id: "14", text: 'Magyar nyelv', value: '马扎尔语' },
                { id: "15", text: 'Português', value: '葡萄牙语' },
                { id: "16", text: '日本語', value: '日本语' },
                { id: "17", text: 'Sverige', value: '瑞典' },
                { id: "18", text: 'Türkçe', value: '土耳其语' },
                { id: "19", text: 'Zimbabwe', value: '津巴布' },
                { id: "20", text: 'Español', value: '乌克兰语' },
                { id: "21", text: 'Español', value: '西班牙语' },
                { id: "22", text: 'Italiano', value: '意大利语' },
                { id: "23", text: 'Bahasa Indonesia', value: '印度尼西亚语' },
                { id: "24", text: 'English', value: '英语' },
                { id: "25", text: 'Tiếng Việt', value: '越南语' },

            ],
            adList: [],
            current: 0,
            currentImage: {
                list: []
            }
        }
    },

    computed: {
        language() {
            const language = this.$store.state.language || '英语'
            return language
        },
    },
    watch: {
        language(language) {
            // console.log(language)
            this.loadData()
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.loading = true
            this.$axios(`https://www.digital-etf.com/home/File/fileList`,)
                .then(({ code, data }) => {
                    if (data.code == 200) {
                        this.adList = data.data

                        const item = this.columns.find(record => record.value == this.language)

                        let item2 = this.adList.find(record => record.id == item.id)

                        if (item2) {
                            this.currentImage = item2
                        } else {
                            let item2 = this.adList.find(record => record.id == 24)
                            this.currentImage = item2
                        }
                        this.loading = false
                    }
                })
        },
        onChange(index) {
            this.current = index;
        },
        ImagePreview(record) {
            this.$ImagePreview([record.image])
        },
        DownLoadImage() {
            const url = this.currentImage.list[this.current].image
            // window.open(url)

            const imageUrl = url;
            const imageName = "myImage.jpg";
            this.downloadIamgeNew2(imageUrl, imageName);
        },

        downloadIamgeNew(src, imgName) {
            let image = new Image()
            image.src = src;
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function () {
                let c = document.createElement("canvas");
                c.width = image.width;
                c.height = image.height;
                c.getContext("2d").drawImage(image, 0, 0, image.width, image.height);
                let a = document.createElement("a");
                a.download = imgName;
                a.href = c.toDataURL("image/png");
                a.click();
            }
        },

        downloadIamgeNew2(url, fileName) {
            // 创建a标签
            var link = document.createElement('a');
            // 设置a标签为不可见
            link.style.display = 'none';
            // 将a标签添加到body
            document.body.appendChild(link);
            // 设置a标签的href
            link.href = url;
            // 设置a标签的download
            link.download = fileName;
            // 模拟点击事件进行下载
            link.click();
            // 下载完成后移除a标签
            document.body.removeChild(link);
        }
    }
}
</script>