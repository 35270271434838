<template>
    <div class="Foundation page-default-config">
        <div class="content">
            <div v-for="(item, index) of contents" :key="index" class="cell" :style="$global.moduleShowAnimation(index)">
                <img :src="item" alt="">
            </div>
        </div>
    </div>
</template>


<script>
import Content1 from "@/assets/img/Foundation/1.png"
import Content2 from "@/assets/img/Foundation/2.png"
import Content3 from "@/assets/img/Foundation/3.png"
import Content4 from "@/assets/img/Foundation/4.png"
import Content5 from "@/assets/img/Foundation/5.png"
export default {
    data() {
        return {
            contents: [   
                Content1,
                Content2,
                Content3,
                Content4,
                Content5,
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.Foundation {
    background-color: #fff;
    .content {
        img {
            width: 100%;
        }
    }
}
</style>