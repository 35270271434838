<template>
    <div class="Home">
        <Part1 :style="$global.moduleShowAnimation(1)"></Part1>
        <Part2 :style="$global.moduleShowAnimation(2)"></Part2>
        <Part3 :style="$global.moduleShowAnimation(3)"></Part3>
        <Part4 :style="$global.moduleShowAnimation(4)"></Part4>
        <Part5 :style="$global.moduleShowAnimation(5)"></Part5>
        <Part6 :style="$global.moduleShowAnimation(6)"></Part6>
    </div>
</template>

<style scoped lang="scss">

.Home {
    width: 100%;
    // height: 100vh;
    background-color: #030C38;
    color: #fff;
    padding-top: 72px;
    padding-bottom: 72px;
    overflow-y: auto;

    .part {
        text-align: center;
        font-style: normal;
        text-transform: none;
        font-family: DIN, DIN;
        font-weight: bold;
        margin-bottom: 40px;
    }
}
</style>

<script>
import Part1 from "./modules/Part1.vue";
import Part2 from "./modules/Part2.vue";
import Part3 from "./modules/Part3.vue";
import Part4 from "./modules/Part4.vue";
import Part5 from "./modules/Part5.vue";
import Part6 from "./modules/Part6.vue";
export default {
    components: {
        Part1,
        Part2,
        Part3,
        Part4,
        Part5,
        Part6
    },
    data() {
        return {
            
        }
    }
}
</script>