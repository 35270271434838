<template>
    <div class="part4 part">
        <div class="font-size__36px margin-bottom__10px">
            {{ $t(`guanwang1.us17`) }}
        </div>

        <div class="part4-text margin-bottom__20px">
            {{ $t(`guanwang1.us18`) }} <span class="font-family-Number font-size__20px">1000000000</span>
        </div>

        <div class="part4-content font-family-Number">
            <div class="part4-content-number">
                1%
            </div>
            <div class="part4-content-inset">
                99%
            </div>
        </div>

        <div class="part4-data">
            <div class="part4-data-left">
                <img :src="ShowImage" alt="">
            </div>
            <div class="part4-data-right font-family-Number">
                <div class="part4-data-right__top display-flex__align-center__justify-between">
                    <div class="title">
                        {{ $t(`guanwang1.us19`) }}
                    </div>
                    <div class="data">
                        1%
                    </div>
                </div>
                <div class="part4-data-right__bottom display-flex__align-center__justify-between">
                    <div class="title">
                        {{ $t(`guanwang1.us20`) }}
                    </div>
                    <div class="data">
                        99%
                    </div>
                </div>
            </div>
        </div>

        <div class="part4-image">
            <img :src="ShowImage2" alt="">
        </div>
    </div>
</template>

<style scoped lang="scss">
.part4 {

    .part4-image {
        width: 100%;
        img {
            width: 90%;
        }
    }

    .part4-data {
        padding: 10px;
        width: 90%;
        height: 100px;
        margin: 10px auto;

        background: linear-gradient( 180deg, rgba(61,115,255,0) 0%, rgba(61,127,255,0.3) 100%);
        border: 1px solid;
        border-image: linear-gradient(360deg, rgba(61, 139, 255, 1), rgba(61, 162, 255, 0)) 1 1;

        position: relative;
        top: 20px;
        .part4-data-left {
            float: left;
            width: 100px;

            img {
                width: 100px;
                height: 80px;
                object-fit: contain;
            }
        }
        .part4-data-right {
            width: calc(100% - 100px);
            box-sizing: border-box;
            float: right;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 80px;
            padding: 10px;
            .part4-data-right__top {
                width: 100%;
                border-bottom: 1px dashed #7789CD;
                padding: 5px 0;
            }
            .part4-data-right__bottom {
                width: 100%;
                padding: 5px 0;
            }

            .title {
                font-size: 16px;
                color: #FFFFFF;
            }
            .data {
                font-size: 24px;
                color: #839EFF;
                line-height: 24px;
            } 
        }
    }
    .part4-text {
        font-size: 18px;
        line-height: 20px;
        background: linear-gradient(0deg, #3877F2 0%, #0029FF 100%);
        -webkit-background-clip: text;
        color: transparent;
    }

    .part4-content {
        width: 90%;
        height: 20px;
        margin: 0 auto;
        border-radius: 10px;
        background: linear-gradient( 90deg, #2D5CBD 0%, #40A0FA 100%);
        position: relative;

        .part4-content-number {
            position: absolute;
            height: 20px;
            color: #fff;
            line-height: 20px;
            right: 10px;
        }

        .part4-content-inset {
            width: 92%;
            height: 20px;
            border-radius: 10px;
            background: linear-gradient( 90deg, rgba(8,255,255,0.1) 0%, #08FFFF 100%);

            color: #fff;
            line-height: 20px;
            padding-left: 10px;

            position: relative;
            z-index: 1;
        }
    }
}
</style>

<script>
import ShowImage from "@/assets/img/Home/Group 1321316819@2x.png"
import ShowImage2 from "@/assets/img/Home/组合 85@2x.png"

export default {
    data() {
        return {
            ShowImage,
            ShowImage2
        }
    }
}
</script>