<template>
    <div class="part2 part">
        <div class="part2-title margin-bottom__20px font-size__36px">
            {{ $t(`guanwang1.us4`) }}
        </div>
        <div class="part2-text-1 font-size__14px margin-bottom__20px padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us5`) }}
        </div>

        <div class="part2-line padding-top__20px">
            
            <div v-for="(item, index) of lineData" :key="index" class="part2-line-cell">
                <div class="part2-line-cell__left">
                    <div v-if="index % 2 == 0" class="part2-line-cell__content float-right">
                        {{ $t(item.contentKey) }}
                    </div>
                    <div v-else class="part2-line-cell__date float-right text-align__right">
                        {{ $t(item.dateKey) }}
                    </div>
                </div>
                <div class="part2-line-cell__right">   
                    <div v-if="index % 2 != 0" class="part2-line-cell__content float-left">
                        {{ $t(item.contentKey) }}
                    </div>
                    <div v-else class="part2-line-cell__date float-left text-align__left">
                        {{ $t(item.dateKey) }}
                    </div>
                    <div class="sphere"></div>
                </div>
            </div>

            <div class="line"></div>
        </div>
    </div>
</template>

<script>
import BorderContent from "@/assets/img/Home/Group 17@2x(1).png"
export default {
    data() {
        return {
            BorderContent,
            lineData: [{
                tKey: 'line1',
                content: 'ETF 挖掘',
                date: '2024 年 8 月',

                contentKey: 'guanwang1.us6',
                dateKey: 'guanwang1.us7'
            },{
                tKey: 'line2',
                content: 'IM 通讯',
                date: '2024 年 11 月',

                contentKey: 'guanwang1.us8',
                dateKey: 'guanwang1.us9'
            },{
                tKey: 'line3',
                content: 'EETF公链测试网',
                date: '2025 年 5 月',

                contentKey: 'guanwang1.us10',
                dateKey: 'guanwang1.us11'
            },{
                tKey: 'line4',
                content: 'ETF 主网',
                date: '2025 年 8 月',

                contentKey: 'guanwang1.us12',
                dateKey: 'guanwang1.us13'
            },{
                tKey: 'line5',
                content: 'ETF 生态',
                date: '2025 年 12 月',

                contentKey: 'guanwang1.us14',
                dateKey: 'guanwang1.us15'
            }]
        }
    }
}
</script>

<style scoped lang="scss">
.part2 {
    

    .part2-line {
        padding: 20px 0 0;
        position: relative;
        .line {
            position: absolute;
            width: 4px;
            height: 78%;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            background: #0066FE;
            border-radius: 0px 0px 0px 0px;
        }
        .part2-line-cell {
            width: 100%;
            min-height: 160px;

            .part2-line-cell__left {
                width: 50%;
                float: left;
                padding-right: 20px;
            }
            .part2-line-cell__right {
                width: 50%;
                float: right;
                padding-left: 20px;
                position: relative;
                .sphere {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -8px;
                    width: 16px;
                    height: 16px;
                    background: #1D77FF;
                    border-radius: 50%;
                    z-index: 1;
                }
            }

            .part2-line-cell__content {
                width: 150px;
                min-height: 70px;
                background-image: url('~@/assets/img/Home/Group 17@2x(1).png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 16px;
                text-align: left;
                padding: 20px;
            }
            .part2-line-cell__date {
                // width: 140px;
                min-height: 70px;
                font-size: 20px;
                color: #0075FE;
                line-height: 23px;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>