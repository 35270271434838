<template>
    <div class="part3 part padding-bottom__20px">
        <div class="part3-title font-size__36px margin-bottom__20px  padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us16`) }}
        </div>

        <div class="part3-content">
            <img :src="RoundImage" alt="">
        </div>
    </div>
</template>


<style scoped lang="scss">
.part3 {
    .part3-content {
        padding: 20px;
        overflow: hidden;
        img {
            width: 100%;
            height: 45vh;
            object-fit: contain;
            display: block;
            transform: scale(1.5);
        }
    }
}
</style>

<script>
import RoundImage from "@/assets/img/Home/gif/2_100.gif"
export default {
    data() {
        return {
            RoundImage
        }
    }
}
</script>