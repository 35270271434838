<template>
    <div class="part1 part">
        <div class="earth">
            <img :src="EarthImage" alt="">
        </div>

        <div class="part1-text-1 margin-bottom__50px">
            EXCHANGE TRADED FUND
        </div>

        <div class="part1-title font-size__24px margin-bottom__20px padding-left__5px padding-right__5px">
            {{ $t(`guanwang1.us1`) }}
        </div>


        <div class="part1-text-3 display-flex__align-center__justify-center">
            <img :src="Icon1" alt="" class="margin-right__5px"> {{ $t(`guanwang1.us2`) }}
        </div>

        <div class="part1-text-4">
            <div class="display-flex__align-center__justify-center" @click="Copy">
                <div class="address">
                    0x67009eB16ff64d06b4F782b3c552b924B1D1bb93
                </div>
                <img class="button-icon" :src="copyIcon">
            </div> 
        </div>

        <div class="part1-button">
            <button @click="Jump">
                {{ $t(`guanwang1.us3`) }}
            </button>
        </div>

        <div class="part1-icons">
            <div v-for="item of 3" :key="item" class="part1-icons__cell">
                <!-- <img class="top" :src="TopModuleImage" alt="">
                <img class="light" :src="LightModuleImage" alt="">
                <img class="bottom" :src="BottomModuleImage" alt=""> -->

                <img class="iconGif" :src="IconGif" alt="">
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.part1 {
    > div {
        margin: 0px auto 10px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        font-family: DIN, DIN;
        font-weight: bold;
    }
    .part1-icons {
        display: flex;
        width: 95%;

        .part1-icons__cell {
            flex: 1;
            position: relative;
            height: 150px;
            display: flex;
            justify-content: center;
            width: 100px;
            transform: scale(.8);
            overflow: hidden;


            img {
                display: block;
                position: absolute;
                // left: 50%;
                // transform: translateX(-50%);
            }
            .iconGif {
                width: 100%;
                transform: scale(1.5);
            }
            .top {
                width: 100px;
                height: 100px;
                object-fit: contain;
                z-index: 2;
                opacity: .8;
                animation: opacity108 3s infinite;
            }
            .light {
                top: 40px;
                z-index: 3;
                width: 120px;
                animation: opacity01 3s infinite;
                opacity: 0;
            }
            .bottom {
                width: 100px;
                height: 60px;
                top: 70px;
            }
        }
    }
    .part1-button {
        button {
            background: linear-gradient( 90deg, #3877F2 0%, #0029FF 100%);
            border-radius: 8px 8px 8px 8px;
            padding: 8px 18px;
            display: inline-block;
        }
    }
    .part1-text-4 {
        font-size: 12px;
        color: #0285FF;
        margin-bottom: 30px;
        padding: 10px;
        

        .address {
            display: inline-block;

            &:active {
                color: rgba(2,133,255,.3);
                border-bottom: 1px solid rgba(2,133,255,.3);
            }
        }
    }

    .part1-title {
        margin-bottom: 30px;
    }
    .part1-text-3 {
        font-size: 20px;
        color: #277CF7;
        line-height: 30px;
        margin-bottom: 5px;

        img {
            height: 24px;
            width: 24px;
        }
    }
    .part1-text-2 {
        font-size: 18px;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .part1-text-1 {
        font-size: 24px;
        line-height: 20px;
        background: linear-gradient(0deg, #3877F2 0%, #0029FF 100%);
        -webkit-background-clip: text;
        color: transparent;
        margin-bottom: 50px;
    }
    .earth {
        overflow: hidden;
        img {
            width: 100%;
            height: 45vh;
            object-fit: contain;
            display: block;
            transform: scale(1.5);
        }
    }
}


</style>

<script>
import EarthImage from "@/assets/img/Home/gif/3_100.gif"
import Icon1 from "@/assets/img/Home/Group 1321316817@2x.png"
import copyIcon from "@/assets/img/copy.png"


import IconGif from "@/assets/img/Home/gif/1_100.gif"
// import TopModuleImage from "@/assets/img/Home/Group 1321316813@2x.png"
// import LightModuleImage from "@/assets/img/Home/Group 1321316814@2x.png"
// import BottomModuleImage from "@/assets/img/Home/Group 1321316767@2x.png"

export default {
    data() {
        return {
            EarthImage,
            Icon1,
            copyIcon,
            IconGif
            // TopModuleImage,
            // LightModuleImage,
            // BottomModuleImage
        }
    },
    methods: {
        Copy() {
            this.$global.CopyContent('0x67009eB16ff64d06b4F782b3c552b924B1D1bb93', () => {
                this.showSuccessMessage(this.$i18n.t('tishi.us1111'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'))
            })
        },
        Jump() {
            this.$router.push('/Mint')
        }
    }
}
</script>