<template>
    <div class="Swap">
        
        <div class="token-swap module-block module-block__bg4 module-radius position__relative font-weight__700" :style="$global.moduleShowAnimation(9)">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>{{ $t(`wakuang2.duihuan`) }}</div>
                <div></div>
            </div>
            <div class="padding-bottom__10px">
                <Swap v-if="swapType" ref="Swap1" pageType="1" @close="$refs.Swap2.loadData()" @cut="swapType = !swapType"></Swap>
                
                <Swap v-else ref="Swap2" pageType="2" @close="$refs.Swap1.loadData()" @cut="swapType = !swapType"></Swap>
            </div>
        </div>
    </div>
</template>
<script>
import Swap from "./component.vue"
export default {
    components: {
        Swap
    },
    data() {
        return {

            swapType: true,
        }
    }
}
</script>

<style scoped lang="scss">
.Swap {
    background-image: url(~@/assets/img/Mint/bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    width: 100%;
    height: 100vh;

    overflow-y: auto;

    padding-top: 20vh;

    
    .token-swap {
        padding: 40px 60px 56px;
    }
}
</style>