<template>
    <div class="CallUs text-align__left font-size__16px">
        <div v-for="(item, index) of callList" :key="index">
            <div>{{ item.label }}</div>
            <div @click="Copy(item)">
                {{ item.content }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            callList: [{
                label: 'Email：',
                content: 'etf.digital.token@gmail.com'
            }, {
                label: 'TG：',
                content: 'https://t.me/ETFDigital'
            }, {
                label: 'TW：',
                content: 'https://twitter.com/ETFDigital'
            }, {
                label: 'OS：',
                content: 'www.digital-etf.com'
            }]
        }
    },
    methods: {
        Copy(item) {
            this.$global.CopyContent(item.content, () => {
                this.showSuccessMessage(this.$i18n.t('tishi.us1111'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'))
            })
        }
    }
}
</script>

<style scoped lang="scss">
.CallUs {
    padding: 20px 40px;
    font-weight: 500;
    position: relative;
    z-index: 2;

    >div {
        display: flex;
        margin-bottom: 5px;

        >div:nth-of-type(1) {
            color: #4986FF;
            font-weight: 700;
            width: 60px;
        }

        >div:nth-of-type(2) {
            border-bottom: 1px solid #fff;

            &:active {
                color: rgba(255, 255, 255, .3);
            }
        }
    }

}
</style>