<template>
    <div class="part part6">
        <div class="font-size__36px">
            {{ $t(`guanwang1.us30`) }}
        </div>

        <div class="part6-earth">
            <img :src="EarthGif" alt="">
        </div>

        <div class="part6-content">
            <call-us></call-us>
        </div>
    </div>
</template>

<script>
import CallUs from "@/components/CallUs.vue"
import EarthGif from "@/assets/img/Home/gif/4_100.gif"

export default {
    components: {
        CallUs
    },
    data() {
        return {
            EarthGif,
            
        }
    }
}
</script>

<style scoped lang="scss">
.part6 {

    .part6-earth {
        width: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 50vh;
            object-fit: contain;
            display: block;
            transform: scale(1.5);
        }
    }
}
</style>