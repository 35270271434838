<template>
    <div class="config">
        <div class="module-block position__relative">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>合约地址</div>
                <div></div>
            </div>
            <div class="padding__10px padding-top__20px">
                <textarea class="content-approve-address padding__10px font-size__12px margin-bottom__10px margin-top__10px" type="number" v-model="address"
                    :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }"
                    >

                </textarea>
                <div class="display-flex__align-center__justify-between">
                    <div class="button width__100per" @click="SetAddress">设置</div>
                    <div class="button width__100per" @click="SetDefaultAddress">设置为默认地址</div>
                </div>
            </div>
        </div>
        
        
        <div class="module-block position__relative">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>Swap地址</div>
                <div></div>
            </div>
            <div class="padding__10px padding-top__20px">
                <textarea class="content-approve-address padding__10px font-size__12px margin-bottom__10px margin-top__10px" type="number" v-model="addressSwap"
                    :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }"
                    >

                </textarea>
                <div class="display-flex__align-center__justify-between">
                    <div class="button width__100per" @click="SetAddressSwap">设置</div>
                    <div class="button width__100per" @click="SetDefaultAddressSwap">设置为默认地址</div>
                </div>
            </div>
        </div>
        
        
        <div class="module-block position__relative">
            <div class="module-block__title display-flex__align-center__justify-center">
                <div></div>
                <div>超级私募地址</div>
                <div></div>
            </div>
            <div class="padding__10px padding-top__20px">
                <textarea class="content-approve-address padding__10px font-size__12px margin-bottom__10px margin-top__10px" type="number" v-model="addressSuper"
                    :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }"
                    >

                </textarea>
                <div class="display-flex__align-center__justify-between">
                    <div class="button width__100per" @click="SetAddressSuper">设置</div>
                    <div class="button width__100per" @click="SetDefaultAddressSuper">设置为默认地址</div>
                </div>
            </div>
        </div>

        <div class="padding-top__20px padding-bottom__20px">

        </div>
    </div>
</template>

<script>
import ApproveAddressBg from "@/assets/img/Private/approve-address.png"
import contractToken from '@/views/Index/js/contract'
import contractSwap from '@/views/Swap/js/contract'
import contractSuper from '@/views/Private/js/contract'
export default {
    data() {
        return {
            imgList: {
                ApproveAddressBg
            },
            address: contractToken.address,
            addressSwap: contractSwap.address,
            addressSuper: contractSuper.address
        }
    },
    created() {
        const localStorageAddress = localStorage.getItem('contractAddress')
        if (localStorageAddress) {
            this.address = localStorageAddress
        } else if (contractToken.address) {
            this.address = contractToken.address
        }

        
        const localStorageAddressSwap = localStorage.getItem('contractSwapAddress')
        if (localStorageAddressSwap) {
            this.addressSwap = localStorageAddressSwap
        } else if (contractSwap.address) {
            this.addressSwap = contractSwap.address
        }

        
        
        const localStorageAddressSuper = localStorage.getItem('contractSuperAddress')
        if (localStorageAddressSuper) {
            this.addressSuper = localStorageAddressSuper
        } else if (contractSuper.address) {
            this.addressSuper = contractSuper.address
        }
    },
    methods: {
        SetAddress() {
            localStorage.setItem('contractAddress', this.address)
            this.reload()
        },
        SetDefaultAddress() {
            this.address = contractToken.address
            localStorage.setItem('contractAddress', contractToken.address)
            this.reload()
        },
        SetAddressSwap() {
            localStorage.setItem('contractSwapAddress', this.addressSwap)
            this.reload()
        },
        SetDefaultAddressSwap() {
            this.addressSwap = contractSwap.address
            localStorage.setItem('contractSwapAddress', contractSwap.address)
            this.reload()
        },

        
        SetAddressSuper() {
            localStorage.setItem('contractSuperAddress', this.addressSuper)
            this.reload()
        },
        SetDefaultAddressSuper() {
            this.addressSuper = contractSuper.address
            localStorage.setItem('contractSuperAddress', contractSuper.address)
            this.reload()
        },

        back() {
            this.$router.back()
            // this.$router.replace(`/?shareurl=${this.$route.query.shareurl || ''}`)
        },
        reload() {
            this.showSuccessMessage(this.$i18n.t('tishi.us888'));
            setTimeout( () => {
                window.location.reload();
            }, 1000)
        }
    }
}
</script>

<style scoped lang="scss">
.config {
    padding-top: 10vh;
    text-align: center;
    textarea {
        width: 100%;
    }
}
.content-approve-address {
    width: 100%;
    height: 80px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    border-radius: 4px;
    overflow: hidden;
    font-size: 16px;
    font-family: Number;

}

.button {
    font-size: 12px!important;
    margin: 0 5px;
}
</style>