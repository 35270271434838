<template>
    <div class="Private" :class="{ activeScroll: scrolleTop > 0 }" :style="$global.moduleShowAnimation(5)">
        <div id="wrapper">
            <div id="circle"></div>
            <div id="circle1"></div>
        </div>
        <div v-if="show" class="Private-box padding__10px padding-bottom__20px">
            <div class="title-box" :style="$global.moduleShowAnimation(1)">
                <!-- <img :src="imgList.TitleModule" alt=""> -->
                <div class="title font-color__white">
                    {{ $t(`chaojijiedian.chaojijiedianhehuorenzhaomu`) }}
                </div>
                <div class="title-sub font-color__white padding__10px padding-top__5px padding-bottom__5px font-size__20px">
                    SUPER NODE PARTNER RECRUITMENT
                </div>
            </div>

            <div class="content">
                <div class="content-sub">
                    <div class="title font-color__white font-size__20px margin-bottom__10px"
                        :style="$global.moduleShowAnimation(2)">
                        {{ $t(`chaojijiedian.wodedizhi`) }}
                    </div>

                    <div class="content-address display-flex__align-center 
                        width__100per 
                        margin-bottom__20px" :style="$global.moduleShowAnimation(2)">
                        <div class="content-address__icon" :style="{ 'backgroundImage': `url(${imgList.ContentIcon})` }">

                        </div>
                        <div class="content-address__input display-flex__align-center__justify-center font-color__white"
                            :style="{ 'backgroundImage': `url(${imgList.ContentInputBg})` }">
                            {{ $global.walletAddressALittle(wallet, 6) }}
                        </div>
                    </div>


                    <div class="title-sub margin-bottom__10px font-size__20px font-color__theme"
                        :style="$global.moduleShowAnimation(3)">
                        {{ $t(`chaojijiedian.shouquandizhi`) }}
                    </div>

                    <van-field class="content-approve-address padding__10px margin-bottom__20px" type="textarea"
                        v-model="inputAddress"
                        :style="{ 'backgroundImage': `url(${imgList.ApproveAddressBg})`, color: '#fff', ...$global.moduleShowAnimation(3) }"
                        @input="handleInputAddress">

                    </van-field>


                    <div :style="$global.moduleShowAnimation(4)"
                        class="title-sub margin-bottom__10px font-size__20px font-color__theme">
                        {{ $t(`chaojijiedian.shifoushichaojijiedian`) }}
                    </div>

                    <div :style="$global.moduleShowAnimation(4)"
                        class="content-des margin-bottom__20px font-size__10px width__100per display-flex__align-center__justify-between font-color__white">
                        <div class="is-super display-flex__align-center__justify-center" :class="{ 'active': isSuperNode == 1 }">
                            <div>
                                <img :src="imgList.YesIcon" alt="">
                            </div>
                            <!-- <div class="font-color__theme">是</div> -->
                            <div>{{ $t(`chaojijiedian.shichaojijiedian`) }}</div>
                        </div>
                        <div class="isnot-super display-flex__align-center__justify-center line-height__1"
                            :class="{ 'active': isSuperNode == 2 }">
                            <img :src="imgList.NoIcon" alt="">
                            <!-- <span class="font-color__theme">不是</span> -->
                            <div>{{ $t(`chaojijiedian.bushichaojijiedian`) }}</div>
                        </div>
                    </div>


                    <div :style="$global.moduleShowAnimation(5)" class="button" @click="Join">
                        <div class="font-color__white">{{ $t(`chaojijiedian.dianjijiaru`) }}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.Private {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: url(~@/assets/img/Private/bg.png) no-repeat;
    background-size: 100% 100%;

    padding-top: 13vh;

    font-family: "Text2";

    box-sizing: border-box;

    position: relative;
    z-index: 2;

    .Private-box {
        transition: all .3s;
        box-shadow: 0 -100px 20px rgba(0, 0, 0, 0);
        border-radius: 10px;
    }

    &.activeScroll {
        .Private-box {
            box-shadow: 0 -100px 20px rgba(0, 0, 0, .5);
        }
    }

    .title-box {
        line-height: 1;

        img {
            width: 80%;
        }

        .title {
            font-size: 32px;
            background-image: linear-gradient(to right, #27d7d1, #6ebff1);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0px 0px 10px rgba(255, 255, 255, .4);
            padding-left: 10px;
            padding-bottom: 2px;

            font-family: "Text";
        }

        .title-sub {
            font-family: "Number";
            color: grey;
        }

    }



    .content {
        width: 100%;
        background-image: url(~@/assets/img/Private/5.gif);
        background-size: 100% 100%;
        margin-top: -20px;

        .content-sub {
            width: 100%;
            min-height: 300px;
            background-image: url(~@/assets/img/Private/content-bg-sub.png);
            background-size: 100% 100%;
            position: relative;
            padding: 100px 45px;

            .title {
                position: relative;
                padding-bottom: 6px;

                &:after {
                    width: 120px;
                    height: 6px;
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    z-index: 1;
                    background-image: linear-gradient(to right, #2f7398, rgba(0, 0, 0, .1));
                }
            }

            .content-address {
                $height: 40px;

                >div {
                    height: $height;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .content-address__icon {
                    width: $height;
                }

                .content-address__input {
                    width: calc(100% - #{$height});
                    font-family: "Number";
                }
            }

            .title-sub {}

            .content-approve-address {
                width: 100%;
                height: 80px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                color: #fff;
                border-radius: 4px;
                overflow: hidden;

                font-family: "Number", "Text";

                ::v-deep .van-field__control {
                    color: #fff;
                }
            }

            .content-des {
                >div {
                    width: 48%;
                    height: 30px;
                    border-radius: 4px;
                    box-shadow: 2px 2px 6px rgba(110, 191, 241, .3);
                    // line-height: 30px;


                    img {
                        width: 10px;
                        height: 10px;
                        display: block;
                        font-size: 0px;
                        line-height: 30px;
                        margin-right: 3px;
                        position: relative;
                        top: 1px;
                    }

                    &.active {
                        border: .5px solid #6ebff1;
                        background-color: rgba(110, 191, 241, .2);
                    }
                }
            }
        }

    }
}
</style>

<script>
import { abi, approveUSDT } from './js/contractMethods'
import TitleModule from "@/assets/img/Private/title.png"
import ContentIcon from "@/assets/img/Private/content-icon.png"
import ContentInputBg from "@/assets/img/Private/content-input-bg.png"
import ApproveAddressBg from "@/assets/img/Private/approve-address.png"
import YesIcon from "@/assets/img/Private/yes.png"
import NoIcon from "@/assets/img/Private/no.png"

export default {
    data() {
        return {
            imgList: {
                TitleModule,
                ContentIcon,
                ContentInputBg,
                ApproveAddressBg,
                YesIcon,
                NoIcon
            },

            inputAddress: "",
            isSuperNode: 3,
            scrolleTop: 0,
            show: false
        }
    },

    computed: {
        wallet() {
            return this.$store.state.wallet;
        },
    },
    mounted() {
        setTimeout(async () => {
            this.show = true
            
            this.isSuperNode = await this.testIsSuperNode()
        }, 500)


        window.addEventListener('scroll', this.handleScroll, true)

        window.onload = function () {
            // 屏幕的尺寸
            var screenW = document.documentElement.clientWidth;
            var screenH = document.documentElement.clientHeight;

            // 2. 动态创建多个星星
            for (var i = 0; i < 100; i++) {
                var span = document.createElement('text');
                document.querySelector(".Private").appendChild(span);

                //位置随机
                var x = parseInt(Math.random() * screenW);
                var y = parseInt(Math.random() * screenH);
                span.style.left = x + 'px';
                span.style.top = y + 'px';
                span.style.zIndex = "0";

                //大小随机
                var scale = Math.random() * 1.5;
                span.style.transform = 'scale(' + scale + ', ' + scale + ')';

                //频率随机
                var rate = Math.random() * 1.5;
                span.style.animationDelay = rate + 's';
            }
        }

    },

    methods: {
        handleScroll() {
            //方法一
            const el = document.querySelector(".Private")
            var top = Math.floor(el.scrollTop || document.documentElement.scrollTop || window.pageYOffset)

            if (top >= 5) {
                this.scrolleTop = 5
            } else {
                this.scrolleTop = top
            }
        },

        /* 检测是否是超级节点 */
        testIsSuperNode(e) {
            // console.log('验证地址', e);
            // console.log('验证方法', '_isPlacement');
            return new Promise(resolve => {
                abi(
                    {
                        abiName: '_isPlacement',
                        params: [this.wallet],
                        type: 'check'
                    },
                    ({ code, data }) => {
                        // console.log('验证结果', data);
                        if (code == 200) {
                            // console.log('_isPlacement', data)
                            const state = data ? 1 : 2
                            resolve(state)
                        } else {
                            // this.showSuccessMessage(this.$i18n.t('tishi.us5555'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                            resolve(3)
                        }
                    }
                );
            })
        },

        async handleInputAddress(e) {
            this.isSuperNode = await this.testIsSuperNode(e)
        },

        /* 获取usdt地址 */
        GetUSDTAddress() {
            return new Promise(resolve => {
                abi(
                    {
                        abiName: "USDT",
                        type: "check",
                        params: []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            // console.log('USDT地址:', data)
                            resolve(data)
                        }
                    }
                );
            })
        },

        /* 授权USDT */
        approveUSDT(usdt) {


            return new Promise(async (resolve) => {

                this.$store.commit("SetLoadingText", this.$i18n.t('tishi.us22222'));
                this.$store.commit("SetLoading", true);

                const USDTdAdress = await this.GetUSDTAddress()
                approveUSDT({
                    number: 5000,
                    address: USDTdAdress
                }, (data) => {
                    if (data) {
                        resolve(true)
                    } else {
                        this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                        this.$store.commit("SetLoading", false);
                        resolve(false)
                    }
                })
            })

        },

        /* 加入 */
        async Join() {

            if(this.isSuperNode === 2) {
                let status = await this.approveUSDT()
                if (!status) return

                this.$store.commit("SetLoadingText", this.$i18n.t('yilou.us77777'));
                this.$store.commit("SetLoading", true);
                abi(
                    {
                        abiName: "placement",
                        params: [this.inputAddress]
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.showSuccessMessage(this.$i18n.t('tishi.us6666'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                        }else {
                            this.showSuccessMessage(this.$i18n.t('tishi.us2222'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
                        }
                        this.$store.commit("SetLoading", false);
                    }
                );
            }else if(this.isSuperNode === 1) {
                this.showSuccessMessage(this.$i18n.t('tishi.us7777'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
            }else {
                this.showSuccessMessage(this.$i18n.t('tishi.us8888'), this.$i18n.t('yilou.us99999'), this.$i18n.t('yilou.us111111'));
            }
            
            
        }
    }
}
</script>


<style>

</style>

<style scoped lang="scss">
.page-header {
    height: 40px;
}
</style>